import React from "react";
import { ThemeContext } from "../layouts";
import PropTypes from "prop-types";
import Article from "../components/Article";

const NotFoundPage = props => {
  return (

    <div style={{padding:90}}>
      <h1>NO SE HA ENCONTRADO</h1>
      <p>Has ido a una página que no existe.</p>
    </div>
  );
};

export default NotFoundPage;
